import { Flex, HStack, Text } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { LocaleSwitchProps } from './LocaleSwitch.props';

const locales: { title: string; code: string; icon: string }[] = [
  {
    title: 'AL',
    code: 'sq',
    icon: '/al.svg',
  },
  {
    title: 'EN',
    code: 'en',
    icon: '/gb.svg',
  },
];
const LocaleSwitch: FC<LocaleSwitchProps> = ({ toggleMobileMenu = () => {} }) => {
  const { locale, pathname, asPath } = useRouter();

  return (
    <Flex justifyContent={{ sm: 'center' }} mt={{ sm: '50px', md: 0 }}>
      {locales.map(({ code, icon, title }) => (
        <Link href={pathname} as={asPath} key={code} locale={code} onClick={toggleMobileMenu}>
          <Text
            opacity={code === locale ? 1 : 0.8}
            textDecor={code === locale ? 'underline' : 'none'}
            color="white"
            ml="20px"
            textTransform="uppercase"
            as="span"
            display="inline-block"
          >
            <HStack>
              <Image src={icon} width={25} alt={title} height={25} /> <span>{title}</span>
            </HStack>
          </Text>
        </Link>
      ))}
    </Flex>
  );
};

export { LocaleSwitch };
