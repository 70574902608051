import imageUrlBuilder from '@sanity/image-url';
import { client } from '@/services/sanity';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';

const builder = imageUrlBuilder(client);

export function sanityImage(source?:SanityImageSource|null) {
  if(source){
    return builder.image(source);
  }

  return null;
}
