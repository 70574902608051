import { Button, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { useRouter } from 'next/router';
import { MotionBox } from '../MotionBox';

interface Props  {
  index:number,
  onToggle:()=>void
  url:string
  text:string
}

const MobileNavItem:FC<Props> = ({index,onToggle,url,text}) => {
  const {push} = useRouter();
  const variants ={
    hidden: {
      y: index+50,
      opacity: 0,
    },
    show:{
      y: 0,
      opacity: 1,
      transition:{
        duration: 0.8,
        delay: 0.3 + index * 0.1,
        ease: [0.075, 0.82, 0.165, 0.8]
        // ease: "easeOut"
      }
    },
    exit:{
      y: 50,
      opacity: 0,
      transition:{
        ease: 'easeIn'
      }
    },
  };

  const handleClickNavItem = () => {
    onToggle();
    setTimeout(() => {
      push(url);
    },500);
  };

  return (
    <MotionBox variants={variants}  initial="hidden" animate="show" exit="exit" >
      <Button
        variant="link"
        py={2}
        onClick={handleClickNavItem}
        w="100%"
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          textAlign="center"
          fontSize="1.4rem"
          fontWeight={400}
          fontFamily="heading"
          color={useColorModeValue('white','black')}>
          {text}
        </Text>
      </Button>
    </MotionBox>
  );
};

export {MobileNavItem};
