import { useLocaleField } from '@/hooks/useLocaleField';
import { useSiteSettings } from '@/hooks/useSiteSettings';
import { Stack } from '@chakra-ui/react';
import { FC } from 'react';
import { MobileNavItem } from './MobileNavItem';

type Props = {
  onToggle:()=>void
}


const MobileNav:FC<Props> = ({onToggle}) => {
  const {header}=useSiteSettings();
  const {getLocaleField}  =useLocaleField();

  return(
    <Stack
      p={4}
      pt={150}
      display={{ md: 'none' }}>
      {header?.navBar?.map((navItem,index) => (
        <MobileNavItem
          onToggle={onToggle}
          index={index}
          key={navItem?._key}
          text={getLocaleField(navItem?.title)||''}
          url={navItem?.url||''}
        />
      ))}
    </Stack>
  );
};

export {MobileNav};
