import { useLocaleField } from '@/hooks/useLocaleField';
import { useSiteSettings } from '@/hooks/useSiteSettings';
import { sanityImage } from '@/utils/sanityImage';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, Container, Flex, HStack, Text, useDisclosure, useMediaQuery , Link } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import Image from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { BsFacebook, BsInstagram } from 'react-icons/bs';
import cn from 'classnames';
import { LocaleSwitch } from '../LocaleSwitch';
import { MotionBox } from '../MotionBox';
import { MenuButton } from './MenuButton';
import { MobileNav } from './MibileNav';
import styles from './Navbar.module.css';
import { NavbarProps } from './Navbar.props';

const Navbar: FC<NavbarProps> = () => {
  const {locale}  =useRouter();
  const { isOpen, onToggle } = useDisclosure();
  const [headerFixed,setHeaderFixed]=useState<boolean>(false);

  const {header,title,instagram,facebook}=useSiteSettings();
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  const mobileMenuSocialDelay = useMemo<number>(() => {
    if(header?.navBar?.length){
      return header.navBar.length * 0.3;
    }

    return 0.3;
  },[header?.navBar?.length]);

  const mobileMenuVariants ={
    hidden:{ opacity: 0 },
    animate: { opacity: 1 },
    exit:{
      opacity: 0,
      transition:{
        delay:0.3
      }
    }
  };

  const mobileMenuSocialVariants ={
    hidden:{ opacity: 0, y: 50 },
    animate: { opacity: 1, transition:{ delay:mobileMenuSocialDelay }, y:0 },
    exit:{
      opacity: 0,
      y: 100
    }
  };

  useEffect(() => {
    if(isOpen){
      document.body.style.overflow = 'hidden';
    }else{

      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  const {getLocaleField}  =useLocaleField();


  useLayoutEffect(() => {
    const fixedTop = 200;
    const fixedHeader = () => {
      setHeaderFixed(window.pageYOffset > fixedTop);
    };

    if(typeof window !== undefined){
      window.addEventListener('scroll', fixedHeader);
    }
  }, []);

  return(
    <>
      <Container
        className={cn(styles.root,{[styles.fixed]:headerFixed},{[styles.mobileMenuOpen]:isOpen})}
        maxW="100%" py="10px" id="mainHeader">
        <Flex direction="column">
          <Flex
            borderBottom={{base:'none', md:`solid 1px ${useColorModeValue('whiteAlpha.200','backAlpha.200')}` }}
            mb="10px"
            alignItems="center"
            justifyContent="space-between">
            <Flex display={{base:'none',md:'flex'}}  direction="row" className={styles.external_links}>
              {header?.externalLinks?.map((navLink) => (
                <Link
                  locale={locale}
                  key={navLink?._key}
                  as={NextLink}
                  href={navLink?.url||'/'}
                  title={getLocaleField(navLink?.title)||''}
                  target={navLink?.newTab? '_blank':'_self'}
                >
                  <Text color="white"> {getLocaleField(navLink?.title)||'Untitled'} <span> &nbsp; </span></Text>
                </Link>
              ))}
            </Flex>
            <Box>
              {isLargerThan768&&
                <LocaleSwitch />
              }
            </Box>
            <Box display={{base:'block',md:'none'}}>
              <MenuButton onPress={onToggle} isOpen={isOpen} />
            </Box>
          </Flex>
          <Flex alignItems="center" justifyContent={{base:'center', md:'space-between'}}>
            <Box mt={{base:'-40px', md:0}} w={{base:'70px', md:'100px'}}>
              <Link
                locale={locale}
                as={NextLink}
                href="/"
                title={title||'Home'}
                className={styles.navBarLogoWrapper} >
                <Image
                  className={styles.navBarLogo}
                  src={sanityImage(header?.logo)?.width(100).url()||''}
                  alt={title||'Home'}
                  width={100}
                  height={64}
                />
                {(isLargerThan768 || (!headerFixed &&!isLargerThan768)||isOpen)&&
                  <span className={styles.navBarLogoText}>{title||'Home'}</span>
                }
              </Link>
            </Box>
            <HStack
              as="nav"
              spacing={4}
              display={{ base: 'none', md: 'flex' }}
            >
              {header?.navBar?.map((navLink) => (
                <Text key={navLink?._key} fontFamily="menubar" as="span" fontSize="lg" ml="5" color="MenuText">
                  <Link
                    locale={locale}
                    key={navLink?._key}
                    as={NextLink}
                    href={navLink?.url||'/'}
                    title={getLocaleField(navLink?.title)||''}
                    target={navLink?.newTab? '_blank':'_self'}
                  >
                    {getLocaleField(navLink?.title)||'Untitled'}
                  </Link>
                </Text>
              ))}
            </HStack>
          </Flex>
        </Flex>
      </Container>
      <AnimatePresence>
        {isOpen&&
        <MotionBox
          variants={mobileMenuVariants}
          animate="animate" initial="hidden"
          exit="exit"
          className={styles.mobileNavWrapper}
        >
          <Box position="fixed">
            <MobileNav onToggle={onToggle} />
            <MotionBox
              variants={mobileMenuSocialVariants}
              animate="animate" initial="hidden"
              exit="exit"
              className={styles.mobileNavFooterWrapper}
            >
              <Text mb={2} color="white"> Follow us</Text>
              <HStack justifyContent="center">
                {facebook&&
                <a
                  className={styles.mobileNavSocialLink}
                  href={facebook}
                  target="_blank"
                  rel="noreferrer">
                  <BsFacebook/>
                </a>
                }
                {instagram&&
                <a
                  className={styles.mobileNavSocialLink}
                  href={instagram}
                  target="_blank"
                  title="Instagram"
                  rel="noreferrer" >
                  <BsInstagram />
                </a>
                }
              </HStack>
              {!isLargerThan768&&
            <Flex align="center" justifyContent="center" mt={10}>
              <LocaleSwitch toggleMobileMenu={onToggle} />
            </Flex>
              }
            </MotionBox>
          </Box>

        </MotionBox>
        }
      </AnimatePresence>
    </>
  );
};

export { Navbar };
