import { useContext } from 'react';
import { SiteSettingsContext } from '../providers/siteSettingsProvider';

export function useSiteSettings() {
  const contextValue = useContext(SiteSettingsContext);

  if (!contextValue) {
    throw new Error('Wrap your components tree with a SiteSettingsContext component');
  }

  return contextValue;
}
