import { useLocaleField } from '@/hooks/useLocaleField';
import { useSiteSettings } from '@/hooks/useSiteSettings';
import { sanityImage } from '@/utils/sanityImage';
import { Box, Container, Divider, Flex, Text, VStack } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';
import { BsFacebook, BsInstagram } from 'react-icons/bs';
import styles from './Footer.module.css';
import { FooterProps } from './Footer.props';

const Footer: FC<FooterProps> = () => {
  const { footer, title, facebook, instagram, mobile, email, web } = useSiteSettings();
  const { getLocaleBlock, getLocaleField } = useLocaleField();

  return (
    <Box backgroundColor="black" py="30px">
      <Container maxW="container.xl">
        <Flex flexDir={{ base: 'column', md: 'row' }}>
          <Box className={styles.column} flex="1" mb="25px">
            <Flex mb="15px">
              {footer?.logo && (
                <Image
                  src={sanityImage(footer.logo)?.width(110).height(70).fit('max').url() || ''}
                  width={110}
                  height={70}
                  alt="HotelTemplate logo"
                />
              )}
              <Text color="whiteAlpha.800" as="h3" ml="15px" fontSize="1.5rem" fontFamily="heading">
                {title}
              </Text>
            </Flex>
            <Text color="whiteAlpha.700" as="p">
              {getLocaleField(footer?.description)}
            </Text>
          </Box>
          <Box className={styles.column} flex="1" mb="25px" as="nav">
            <Box>
              <Text color="whiteAlpha.900" className={styles.column_heading} as="h3" fontFamily="heading">
                Quick links
              </Text>
              <ul>
                {footer?.quickLinks?.map((link) => (
                  <li key={link?._key} className={styles.list_item}>
                    <Link href={link?.url || '/'}>
                      <Text fontSize="1.1rem" as="span" color="whiteAlpha.700">
                        {getLocaleField(link?.title)}
                      </Text>
                    </Link>
                  </li>
                ))}
              </ul>
            </Box>
          </Box>
          <Box className={styles.column} flex="1" mb="25px" as="nav">
            <Box>
              <Text color="whiteAlpha.900" className={styles.column_heading} as="h3" fontFamily="heading">
                Contact
              </Text>
              <ul>
                <li className={styles.list_item}>
                  <Link href={`tel:${mobile}`}>
                    <Text fontSize="1.1rem" as="span" color="whiteAlpha.700">
                      {mobile}
                    </Text>
                  </Link>
                </li>
                <li className={styles.list_item}>
                  <Link href={`mailto:${email}`}>
                    <Text fontSize="1.1rem" as="span" color="whiteAlpha.700">
                      {email}
                    </Text>
                  </Link>
                </li>
                <li className={styles.list_item}>
                  <a href={web || '/'}>
                    <Text fontSize="1.1rem" as="span" color="whiteAlpha.700">
                      {web}
                    </Text>
                  </a>
                </li>
              </ul>
            </Box>
          </Box>
          <Box className={styles.column} flex="1" as="nav">
            <Text color="whiteAlpha.900" className={styles.column_heading} as="h3" fontFamily="heading">
              Social
            </Text>
            <VStack alignItems="start" spacing={5} color="whiteAlpha.700">
              <a className={styles.social_link_item} href={facebook || '/'} target="_blank" rel="noreferrer">
                <BsFacebook /> Facebook
              </a>
              <a className={styles.social_link_item} href={instagram || '/'} target="_blank" rel="noreferrer">
                <BsInstagram /> Instagram
              </a>
            </VStack>
          </Box>
        </Flex>
        <Divider mt="35px" mb="15px" />
        {/* <Flex justifyContent="space-between" flexWrap="wrap">
          <Box>
            {footer?.other_footer_links?.map((other_link) => (
              <CustomLink key={other_link.id} link={other_link}>
                <Text fontSize="1.1rem" as="span" display="inline-block" mr="20px" color="white">
                  {other_link.text}
                </Text>
              </CustomLink>
            ))}
          </Box>
          <Box>
            <Text color="white">{footer.smallText}</Text>
          </Box>
        </Flex> */}
      </Container>
    </Box>
  );
};

export { Footer };
