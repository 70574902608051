import Head from 'next/head';
import { FC } from 'react';
import { useSiteSettings } from '@/hooks/useSiteSettings';
import { Navbar } from '../Navbar';
import styles from './Layout.module.css';
import { LayoutProps } from './Layout.props';
import '@fontsource/merriweather';
import '@fontsource/open-sans';
import { Footer } from '../Footer';

const Layout: FC<LayoutProps> = ({ children, pageTitle }) => {
  const {title,description} = useSiteSettings();
  const url = process.env.NEXT_PUBLIC_WEB_URL;

  return(
    <div className={styles.layoutWrapper}>
      <Head>
        <title>{pageTitle} - {title} </title>
        <meta name="description" content={description ||''} />
        <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png"/>
        <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png"/>
        <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png"/>
        <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png"/>
        <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png"/>
        <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png"/>
        <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png"/>
        <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png"/>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png"/>
        <link rel="icon" type="image/png" sizes="192x192"  href="/android-icon-192x192.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
        <link rel="manifest" href="/manifest.json"/>
        <meta name="msapplication-TileColor" content="#a29060"/>
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png"/>
        <meta name="theme-color" content="#a29060"/>
        <meta name="locale" content="en" />
        <meta name="language" content="en" />
        <meta name="twitter:widgets:csp" content="on" />
        <meta name="twitter:card" content={description||''} />
        <meta name="twitter:title" content={title||''} />
        <meta name="twitter:description" content={description||''} />
        <meta name="twitter:image" content={`${url}/api/og`} />
        <meta name="twitter:url" content="https://theboatapp.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title||''} />
        <meta property="og:description" content={description||''} />
        <meta property="og:image" content={`${url}/api/og`} />
        <meta property="og:url" content="https://theboatapp.com/" />
        <meta property="og:site_name" content={title||''} />
        <meta property="og:locale" content="en" />
      </Head>
      <Navbar />
      <main className={styles.main}>{children}</main>
      <Footer />
    </div>
  );
};

export { Layout };
