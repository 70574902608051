import {createClient} from 'next-sanity';

const projectId = process.env.NEXT_PUBLIC_SANITY_PROJECT_ID; // "pv8y60vp"
const dataset = process.env.NEXT_PUBLIC_SANITY_DATASET; // "production"
const apiVersion = process.env.NEXT_PUBLIC_SANITY_API_VERSION; // "2022-11-16"
const token = process.env.NEXT_PUBLIC_SANITY_API_TOKEN; // "2022-11-16"

const client = createClient({
  projectId,
  dataset,
  useCdn: typeof document !== 'undefined',
  apiVersion,
  token
});

export { client };
