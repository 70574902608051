import { LocaleBlock, LocaleString, LocaleText } from '@/services/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useRouter } from 'next/router';

type TypeLocale = 'en'|'sq'
type TypeBlock = 'enRaw'|'sqRaw'

const useLocaleField=() => {
  const {locale,defaultLocale}  =useRouter();
  const getLocaleField =(field?: Maybe<LocaleString> | Maybe<LocaleText>  ) => {
    if(locale && field){
      if(field[locale as TypeLocale]){
        return field[locale as TypeLocale];
      }

      if(field[defaultLocale as TypeLocale]){
        return field[defaultLocale as TypeLocale];
      }

      return '';
    }

    return '';
  };

  const getLocaleBlock =(field?: Maybe<LocaleBlock> | Maybe<LocaleBlock>  ) => {
    if(locale && field){
      const key = `${locale}Raw`;
      const defaultKey = `${defaultLocale}Raw`;

      if(field[key as TypeBlock]){
        return field[key as TypeBlock];
      }

      if(field[defaultKey as TypeBlock]){
        return field[defaultKey as TypeBlock];
      }

      return '';
    }

    return '';
  };

  return {
    getLocaleField,
    getLocaleBlock
  };
};

export {useLocaleField};
